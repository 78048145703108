
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  background-color: var(--color-white-absolute);
  pointer-events: none;
  border-radius: 5px;
  color: var(--color-grey-dark);
  padding: 5px 8px;
  bottom: 45px;
  transition: all 250ms ease;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);

  .big {
    margin-bottom: 5px;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .small {
    margin-top: 2px;
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
