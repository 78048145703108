
    @import "config.scss";
 
.container {
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: none;
  .name {
    margin-left: 10px;
  }

  .icon {
    color: white;
    display: flex;
    align-items: center;
    gap: 7px;

    &--checked {
      color: var(--color-primary);
    }
  }
}
