
    @import "config.scss";
 
.container {
  height: 100%;
  .filtersMobileBtn {
    color: white;
    display: flex;
    align-items: center;
    background: #181818;
    border-radius: 10px;
  }
  .close {
    justify-content: flex-end;
    background-color: rgba(255, 255, 255, 0.93);
    width: 100%;

    button {
      padding: 20px 10px;
      margin-right: 20px;
    }
  }

  .content {
    .title {
      display: block;
      margin: 25px 0;
    }
    h1 {
      font-size: 16px !important;
      margin-bottom: 10px !important;
    }
  }
}
.option {
  display: flex;
  align-items: center;
  gap: 15px;
  background: transparent;
  border: none;
  margin-bottom: 3px;
  cursor: pointer;
}

@include for-desktop-up {
  .container {
    overflow: unset;

    .close {
      display: none;
    }

    .content {
      padding: 0;

      .title {
        margin: 0;
      }
    }
  }
}
