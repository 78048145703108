
    @import "config.scss";
 
.container {
  margin: 0 0rem 1.5rem;

  & > div > :first-child {
    margin-bottom: 1rem;
  }
  @include for-desktop-up {
    grid-column: 1/2;
    margin-block: 1rem;
    margin-right: 2vw;
  }
}
