
    @import "config.scss";
 
.container {
  flex-direction: column;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  .selectBtn {
    justify-content: space-between;
    display: flex;
    background: none;
    border: none;
    width: 100%;
    align-items: center;
    .textInBtn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: uppercase;
    }
  }
  .options {
    display: flex;
    color: white;
    overflow-y: scroll;
  }
}
